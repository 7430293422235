/* tslint:disable */
/* eslint-disable */
/**
 * ClearLife Security Authentication API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AcceptInvitationDto
 */
export interface AcceptInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'acceptanceToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationDto
     */
    'newPasswordConfirmed'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'lastAccessed'?: string | null;
}
/**
 * 
 * @export
 * @interface Assembly
 */
export interface Assembly {
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof Assembly
     */
    'definedTypes'?: Array<TypeInfo> | null;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Assembly
     */
    'exportedTypes'?: Array<Type> | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     * @deprecated
     */
    'codeBase'?: string | null;
    /**
     * 
     * @type {MethodInfo}
     * @memberof Assembly
     */
    'entryPoint'?: MethodInfo;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'imageRuntimeVersion'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isDynamic'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'location'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'reflectionOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isFullyTrusted'?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Assembly
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     * @deprecated
     */
    'escapedCodeBase'?: string | null;
    /**
     * 
     * @type {Module}
     * @memberof Assembly
     */
    'manifestModule'?: Module;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Assembly
     */
    'modules'?: Array<Module> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     * @deprecated
     */
    'globalAssemblyCache'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Assembly
     */
    'hostContext'?: number;
    /**
     * 
     * @type {SecurityRuleSet}
     * @memberof Assembly
     */
    'securityRuleSet'?: SecurityRuleSet;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CallingConventions = {
    Standard: 'Standard',
    VarArgs: 'VarArgs',
    Any: 'Any',
    HasThis: 'HasThis',
    ExplicitThis: 'ExplicitThis'
} as const;

export type CallingConventions = typeof CallingConventions[keyof typeof CallingConventions];


/**
 * 
 * @export
 * @interface ChangeDetailsViewModel
 */
export interface ChangeDetailsViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangeDetailsViewModel
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetailsViewModel
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetailsViewModel
     */
    'newPasswordConfirmed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetailsViewModel
     */
    'passwordChangeSuccessMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordResult
 */
export interface ChangePasswordResult {
    /**
     * 
     * @type {boolean}
     * @memberof ChangePasswordResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangePasswordResult
     */
    'faults'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ChangeRolesErrorDto
 */
export interface ChangeRolesErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeRolesErrorDto
     */
    'message': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRolesErrorDto
     */
    'badRoles': Array<string>;
}
/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'issuer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'originalIssuer'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Claim
     */
    'properties'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof Claim
     */
    'subject'?: ClaimsIdentity;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'valueType'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimDto
 */
export interface ClaimDto {
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof ClaimDto
     */
    'claims'?: Array<StringStringKeyValuePair> | null;
}
/**
 * 
 * @export
 * @interface ClaimsIdentity
 */
export interface ClaimsIdentity {
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'authenticationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIdentity
     */
    'isAuthenticated'?: boolean;
    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof ClaimsIdentity
     */
    'actor'?: ClaimsIdentity;
    /**
     * 
     * @type {any}
     * @memberof ClaimsIdentity
     */
    'bootstrapContext'?: any | null;
    /**
     * 
     * @type {Array<Claim>}
     * @memberof ClaimsIdentity
     */
    'claims'?: Array<Claim> | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'nameClaimType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    'roleClaimType'?: string | null;
}
/**
 * 
 * @export
 * @interface ConfirmIdentityViewModel
 */
export interface ConfirmIdentityViewModel {
    /**
     * 
     * @type {string}
     * @memberof ConfirmIdentityViewModel
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ConstructorInfo
 */
export interface ConstructorInfo {
    /**
     * 
     * @type {string}
     * @memberof ConstructorInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof ConstructorInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ConstructorInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConstructorInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof ConstructorInfo
     */
    'attributes'?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof ConstructorInfo
     */
    'methodImplementationFlags'?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof ConstructorInfo
     */
    'callingConvention'?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isAbstract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isConstructor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isHideBySig'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isStatic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isVirtual'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamilyAndAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamilyOrAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isConstructedGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isGenericMethodDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'containsGenericParameters'?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof ConstructorInfo
     */
    'methodHandle'?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecurityTransparent'?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof ConstructorInfo
     */
    'memberType'?: MemberTypes;
}


/**
 * 
 * @export
 * @interface ContactSyncUser
 */
export interface ContactSyncUser {
    /**
     * 
     * @type {string}
     * @memberof ContactSyncUser
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSyncUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSyncUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSyncUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSyncUser
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSyncUser
     */
    'releaseEmailSubscriber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactSyncUser
     */
    'subscriptionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomAttributeData
 */
export interface CustomAttributeData {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeData
     */
    'attributeType'?: Type;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof CustomAttributeData
     */
    'constructor'?: ConstructorInfo;
    /**
     * 
     * @type {Array<CustomAttributeTypedArgument>}
     * @memberof CustomAttributeData
     */
    'constructorArguments'?: Array<CustomAttributeTypedArgument> | null;
    /**
     * 
     * @type {Array<CustomAttributeNamedArgument>}
     * @memberof CustomAttributeData
     */
    'namedArguments'?: Array<CustomAttributeNamedArgument> | null;
}
/**
 * 
 * @export
 * @interface CustomAttributeNamedArgument
 */
export interface CustomAttributeNamedArgument {
    /**
     * 
     * @type {MemberInfo}
     * @memberof CustomAttributeNamedArgument
     */
    'memberInfo'?: MemberInfo;
    /**
     * 
     * @type {CustomAttributeTypedArgument}
     * @memberof CustomAttributeNamedArgument
     */
    'typedValue'?: CustomAttributeTypedArgument;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeNamedArgument
     */
    'memberName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomAttributeNamedArgument
     */
    'isField'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomAttributeTypedArgument
 */
export interface CustomAttributeTypedArgument {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeTypedArgument
     */
    'argumentType'?: Type;
    /**
     * 
     * @type {any}
     * @memberof CustomAttributeTypedArgument
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @interface EndpointsDto
 */
export interface EndpointsDto {
    /**
     * 
     * @type {OrganisationEndpointsDto}
     * @memberof EndpointsDto
     */
    'organisation'?: OrganisationEndpointsDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventAttributes = {
    None: 'None',
    SpecialName: 'SpecialName',
    RtSpecialName: 'RTSpecialName'
} as const;

export type EventAttributes = typeof EventAttributes[keyof typeof EventAttributes];


/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof EventInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof EventInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof EventInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {EventAttributes}
     * @memberof EventInfo
     */
    'attributes'?: EventAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'addMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'removeMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'raiseMethod'?: MethodInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isMulticast'?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'eventHandlerType'?: Type;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FieldAttributes = {
    PrivateScope: 'PrivateScope',
    Private: 'Private',
    FamAndAssem: 'FamANDAssem',
    Assembly: 'Assembly',
    Family: 'Family',
    FamOrAssem: 'FamORAssem',
    Public: 'Public',
    FieldAccessMask: 'FieldAccessMask',
    Static: 'Static',
    InitOnly: 'InitOnly',
    Literal: 'Literal',
    NotSerialized: 'NotSerialized',
    HasFieldRva: 'HasFieldRVA',
    SpecialName: 'SpecialName',
    RtSpecialName: 'RTSpecialName',
    HasFieldMarshal: 'HasFieldMarshal',
    PinvokeImpl: 'PinvokeImpl',
    HasDefault: 'HasDefault',
    ReservedMask: 'ReservedMask'
} as const;

export type FieldAttributes = typeof FieldAttributes[keyof typeof FieldAttributes];


/**
 * 
 * @export
 * @interface FieldInfo
 */
export interface FieldInfo {
    /**
     * 
     * @type {string}
     * @memberof FieldInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof FieldInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof FieldInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof FieldInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {FieldAttributes}
     * @memberof FieldInfo
     */
    'attributes'?: FieldAttributes;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'fieldType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isInitOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isLiteral'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     * @deprecated
     */
    'isNotSerialized'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPinvokeImpl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isStatic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamilyAndAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamilyOrAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecurityTransparent'?: boolean;
    /**
     * 
     * @type {RuntimeFieldHandle}
     * @memberof FieldInfo
     */
    'fieldHandle'?: RuntimeFieldHandle;
}


/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'displayText'?: string | null;
}
/**
 * 
 * @export
 * @interface ForgottenPasswordViewModel
 */
export interface ForgottenPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordViewModel
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordViewModel
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordViewModel
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface ForgottenUsernameViewModel
 */
export interface ForgottenUsernameViewModel {
    /**
     * 
     * @type {string}
     * @memberof ForgottenUsernameViewModel
     */
    'emailAddress': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GenericParameterAttributes = {
    None: 'None',
    Covariant: 'Covariant',
    Contravariant: 'Contravariant',
    VarianceMask: 'VarianceMask',
    ReferenceTypeConstraint: 'ReferenceTypeConstraint',
    NotNullableValueTypeConstraint: 'NotNullableValueTypeConstraint',
    DefaultConstructorConstraint: 'DefaultConstructorConstraint',
    SpecialConstraintMask: 'SpecialConstraintMask'
} as const;

export type GenericParameterAttributes = typeof GenericParameterAttributes[keyof typeof GenericParameterAttributes];


/**
 * 
 * @export
 * @enum {string}
 */

export const InvitationState = {
    None: 'None',
    Invited: 'Invited',
    InviteExpired: 'InviteExpired'
} as const;

export type InvitationState = typeof InvitationState[keyof typeof InvitationState];


/**
 * 
 * @export
 * @enum {string}
 */

export const LayoutKind = {
    Sequential: 'Sequential',
    Explicit: 'Explicit',
    Auto: 'Auto'
} as const;

export type LayoutKind = typeof LayoutKind[keyof typeof LayoutKind];


/**
 * 
 * @export
 * @interface LoginReminderData
 */
export interface LoginReminderData {
    /**
     * 
     * @type {string}
     * @memberof LoginReminderData
     */
    'friendlyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginReminderData
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginReminderData
     */
    'usernames'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LoginReminderData
     */
    'loginUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResult
 */
export interface LoginResult {
    /**
     * 
     * @type {boolean}
     * @memberof LoginResult
     */
    'returnToApplication'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResult
     */
    'enrollMfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResult
     */
    'actionMfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResult
     */
    'newRecoveryCodes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResult
     */
    'restartLogin'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginResult
     */
    'faults'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LoginViewModel
 */
export interface LoginViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    'returnUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginViewModel
     */
    'rememberLogin'?: boolean;
}
/**
 * 
 * @export
 * @interface LogoutResult
 */
export interface LogoutResult {
    /**
     * 
     * @type {string}
     * @memberof LogoutResult
     */
    'redirectUri'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberInfo
 */
export interface MemberInfo {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MemberInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MemberInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MemberInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MemberInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberInfo
     */
    'metadataToken'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MemberTypes = {
    Constructor: 'Constructor',
    Event: 'Event',
    Field: 'Field',
    Method: 'Method',
    Property: 'Property',
    TypeInfo: 'TypeInfo',
    Custom: 'Custom',
    NestedType: 'NestedType',
    All: 'All'
} as const;

export type MemberTypes = typeof MemberTypes[keyof typeof MemberTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const MethodAttributes = {
    PrivateScope: 'PrivateScope',
    Private: 'Private',
    FamAndAssem: 'FamANDAssem',
    Assembly: 'Assembly',
    Family: 'Family',
    FamOrAssem: 'FamORAssem',
    Public: 'Public',
    MemberAccessMask: 'MemberAccessMask',
    UnmanagedExport: 'UnmanagedExport',
    Static: 'Static',
    Final: 'Final',
    Virtual: 'Virtual',
    HideBySig: 'HideBySig',
    NewSlot: 'NewSlot',
    CheckAccessOnOverride: 'CheckAccessOnOverride',
    Abstract: 'Abstract',
    SpecialName: 'SpecialName',
    RtSpecialName: 'RTSpecialName',
    PinvokeImpl: 'PinvokeImpl',
    HasSecurity: 'HasSecurity',
    RequireSecObject: 'RequireSecObject',
    ReservedMask: 'ReservedMask'
} as const;

export type MethodAttributes = typeof MethodAttributes[keyof typeof MethodAttributes];


/**
 * 
 * @export
 * @interface MethodBase
 */
export interface MethodBase {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodBase
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MethodBase
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodBase
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodBase
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodBase
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodBase
     */
    'attributes'?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodBase
     */
    'methodImplementationFlags'?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodBase
     */
    'callingConvention'?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isAbstract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isConstructor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isHideBySig'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isStatic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isVirtual'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamilyAndAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamilyOrAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isConstructedGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isGenericMethodDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'containsGenericParameters'?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodBase
     */
    'methodHandle'?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecurityTransparent'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MethodImplAttributes = {
    Il: 'IL',
    Native: 'Native',
    Optil: 'OPTIL',
    CodeTypeMask: 'CodeTypeMask',
    ManagedMask: 'ManagedMask',
    NoInlining: 'NoInlining',
    ForwardRef: 'ForwardRef',
    Synchronized: 'Synchronized',
    NoOptimization: 'NoOptimization',
    PreserveSig: 'PreserveSig',
    AggressiveInlining: 'AggressiveInlining',
    AggressiveOptimization: 'AggressiveOptimization',
    InternalCall: 'InternalCall',
    MaxMethodImplVal: 'MaxMethodImplVal'
} as const;

export type MethodImplAttributes = typeof MethodImplAttributes[keyof typeof MethodImplAttributes];


/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodInfo
     */
    'attributes'?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodInfo
     */
    'methodImplementationFlags'?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodInfo
     */
    'callingConvention'?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isAbstract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isConstructor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFinal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isHideBySig'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isStatic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isVirtual'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamilyAndAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamilyOrAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isConstructedGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isGenericMethod'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isGenericMethodDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'containsGenericParameters'?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodInfo
     */
    'methodHandle'?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecurityTransparent'?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {ParameterInfo}
     * @memberof MethodInfo
     */
    'returnParameter'?: ParameterInfo;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'returnType'?: Type;
    /**
     * 
     * @type {object}
     * @memberof MethodInfo
     */
    'returnTypeCustomAttributes'?: object;
}


/**
 * 
 * @export
 * @interface MfaRecoveryCodes
 */
export interface MfaRecoveryCodes {
    /**
     * 
     * @type {Array<string>}
     * @memberof MfaRecoveryCodes
     */
    'codes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MfaRegistrationDto
 */
export interface MfaRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof MfaRegistrationDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof MfaRegistrationDto
     */
    'acceptanceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface MfaRegistrationViewModel
 */
export interface MfaRegistrationViewModel {
    /**
     * 
     * @type {string}
     * @memberof MfaRegistrationViewModel
     */
    'sharedKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MfaRegistrationViewModel
     */
    'authenticatorUri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MfaRegistrationViewModel
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MfaRegistrationViewModel
     */
    'faults'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MfaStatusViewModel
 */
export interface MfaStatusViewModel {
    /**
     * 
     * @type {string}
     * @memberof MfaStatusViewModel
     */
    'sharedKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MfaStatusViewModel
     */
    'authenticatorUri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MfaStatusViewModel
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MfaStatusViewModel
     */
    'faults'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MfaStatusViewModel
     */
    'hasMfa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MfaStatusViewModel
     */
    'mfaHelpUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MfaViewModel
 */
export interface MfaViewModel {
    /**
     * 
     * @type {string}
     * @memberof MfaViewModel
     */
    'returnUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof MfaViewModel
     */
    'rememberMe'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MfaViewModel
     */
    'twoFactorCode': string;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Assembly}
     * @memberof Module
     */
    'assembly'?: Assembly;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'fullyQualifiedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'mdStreamVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'moduleVersionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'scopeName'?: string | null;
    /**
     * 
     * @type {ModuleHandle}
     * @memberof Module
     */
    'moduleHandle'?: ModuleHandle;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Module
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'metadataToken'?: number;
}
/**
 * 
 * @export
 * @interface ModuleDto
 */
export interface ModuleDto {
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof ModuleDto
     */
    'roles': Array<RoleDto>;
    /**
     * 
     * @type {Array<ModuleDto>}
     * @memberof ModuleDto
     */
    'submodules': Array<ModuleDto>;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    'displayOrder': number;
}
/**
 * 
 * @export
 * @interface ModuleHandle
 */
export interface ModuleHandle {
    /**
     * 
     * @type {number}
     * @memberof ModuleHandle
     */
    'mdStreamVersion'?: number;
}
/**
 * 
 * @export
 * @interface NotFoundObjectResult
 */
export interface NotFoundObjectResult {
    /**
     * 
     * @type {any}
     * @memberof NotFoundObjectResult
     */
    'value'?: any | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof NotFoundObjectResult
     */
    'formatters'?: Array<object> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotFoundObjectResult
     */
    'contentTypes'?: Array<string> | null;
    /**
     * 
     * @type {Type}
     * @memberof NotFoundObjectResult
     */
    'declaredType'?: Type;
    /**
     * 
     * @type {number}
     * @memberof NotFoundObjectResult
     */
    'statusCode'?: number | null;
}
/**
 * 
 * @export
 * @interface OrganisationDto
 */
export interface OrganisationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    'pendingInvitations'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    'userLimit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    'activeUsersCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    'activeContractualUsers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationDto
     */
    'userLimitReached'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganisationEndpointsDto
 */
export interface OrganisationEndpointsDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisationEndpointsDto
     */
    'allGet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationEndpointsDto
     */
    'mfaRequiredGet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationEndpointsDto
     */
    'mfaRequiredPut'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParameterAttributes = {
    None: 'None',
    In: 'In',
    Out: 'Out',
    Lcid: 'Lcid',
    Retval: 'Retval',
    Optional: 'Optional',
    HasDefault: 'HasDefault',
    HasFieldMarshal: 'HasFieldMarshal',
    Reserved3: 'Reserved3',
    Reserved4: 'Reserved4',
    ReservedMask: 'ReservedMask'
} as const;

export type ParameterAttributes = typeof ParameterAttributes[keyof typeof ParameterAttributes];


/**
 * 
 * @export
 * @interface ParameterInfo
 */
export interface ParameterInfo {
    /**
     * 
     * @type {ParameterAttributes}
     * @memberof ParameterInfo
     */
    'attributes'?: ParameterAttributes;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ParameterInfo
     */
    'member'?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof ParameterInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof ParameterInfo
     */
    'parameterType'?: Type;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isLcid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isRetval'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    'defaultValue'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    'rawDefaultValue'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'hasDefaultValue'?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ParameterInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    'metadataToken'?: number;
}


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PropertyAttributes = {
    None: 'None',
    SpecialName: 'SpecialName',
    RtSpecialName: 'RTSpecialName',
    HasDefault: 'HasDefault',
    Reserved2: 'Reserved2',
    Reserved3: 'Reserved3',
    Reserved4: 'Reserved4',
    ReservedMask: 'ReservedMask'
} as const;

export type PropertyAttributes = typeof PropertyAttributes[keyof typeof PropertyAttributes];


/**
 * 
 * @export
 * @interface PropertyInfo
 */
export interface PropertyInfo {
    /**
     * 
     * @type {string}
     * @memberof PropertyInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof PropertyInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof PropertyInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof PropertyInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'propertyType'?: Type;
    /**
     * 
     * @type {PropertyAttributes}
     * @memberof PropertyInfo
     */
    'attributes'?: PropertyAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'canRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'canWrite'?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    'getMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    'setMethod'?: MethodInfo;
}


/**
 * 
 * @export
 * @interface RegistrationCompleteViewModel
 */
export interface RegistrationCompleteViewModel {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationCompleteViewModel
     */
    'requireMfa'?: boolean;
}
/**
 * 
 * @export
 * @interface RoleChangeDto
 */
export interface RoleChangeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleChangeDto
     */
    'addRoles'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleChangeDto
     */
    'removeRoles'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleChangeDto
     */
    'allRoles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'mutexGroup': string;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'displayOrder'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    'dependsOnRoleNames': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    'requiredByRoleNames': Array<string>;
}
/**
 * 
 * @export
 * @interface RuntimeFieldHandle
 */
export interface RuntimeFieldHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeFieldHandle
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface RuntimeMethodHandle
 */
export interface RuntimeMethodHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeMethodHandle
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface RuntimeTypeHandle
 */
export interface RuntimeTypeHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeTypeHandle
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityRuleSet = {
    None: 'None',
    Level1: 'Level1',
    Level2: 'Level2'
} as const;

export type SecurityRuleSet = typeof SecurityRuleSet[keyof typeof SecurityRuleSet];


/**
 * 
 * @export
 * @interface SentryConfigurationOptions
 */
export interface SentryConfigurationOptions {
    /**
     * 
     * @type {string}
     * @memberof SentryConfigurationOptions
     */
    'dsn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SentryConfigurationOptions
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SentryConfigurationOptions
     */
    'environment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SentryConfigurationOptions
     */
    'application'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SentryConfigurationOptions
     */
    'tracesSampleRate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SentryConfigurationOptions
     */
    'tracePropagationTargets'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SentryConfigurationOptions
     */
    'sendPii'?: boolean;
}
/**
 * 
 * @export
 * @interface StringStringKeyValuePair
 */
export interface StringStringKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface StructLayoutAttribute
 */
export interface StructLayoutAttribute {
    /**
     * 
     * @type {any}
     * @memberof StructLayoutAttribute
     */
    'typeId'?: any | null;
    /**
     * 
     * @type {LayoutKind}
     * @memberof StructLayoutAttribute
     */
    'value'?: LayoutKind;
}


/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Type
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isInterface'?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof Type
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'assemblyQualifiedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Assembly}
     * @memberof Type
     */
    'assembly'?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof Type
     */
    'module'?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNested'?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof Type
     */
    'declaringMethod'?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'underlyingSystemType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isTypeDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isByRef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isConstructedGenericType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericTypeParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericMethodParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericTypeDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSZArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isVariableBoundArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isByRefLike'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isFunctionPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isUnmanagedFunctionPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'hasElementType'?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Type
     */
    'genericTypeArguments'?: Array<Type> | null;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'genericParameterPosition'?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof Type
     */
    'genericParameterAttributes'?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof Type
     */
    'attributes'?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAbstract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isImport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSealed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamANDAssem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamORAssem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNotPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAutoLayout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isExplicitLayout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isLayoutSequential'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAnsiClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAutoClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isUnicodeClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isCOMObject'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isContextful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isEnum'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isMarshalByRef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPrimitive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isValueType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSignatureType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecurityTransparent'?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof Type
     */
    'structLayoutAttribute'?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof Type
     */
    'typeInitializer'?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof Type
     */
    'typeHandle'?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'guid'?: string;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'baseType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     * @deprecated
     */
    'isSerializable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'containsGenericParameters'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isVisible'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TypeAttributes = {
    NotPublic: 'NotPublic',
    Public: 'Public',
    NestedPublic: 'NestedPublic',
    NestedPrivate: 'NestedPrivate',
    NestedFamily: 'NestedFamily',
    NestedAssembly: 'NestedAssembly',
    NestedFamAndAssem: 'NestedFamANDAssem',
    VisibilityMask: 'VisibilityMask',
    SequentialLayout: 'SequentialLayout',
    ExplicitLayout: 'ExplicitLayout',
    LayoutMask: 'LayoutMask',
    Interface: 'Interface',
    Abstract: 'Abstract',
    Sealed: 'Sealed',
    SpecialName: 'SpecialName',
    RtSpecialName: 'RTSpecialName',
    Import: 'Import',
    Serializable: 'Serializable',
    WindowsRuntime: 'WindowsRuntime',
    UnicodeClass: 'UnicodeClass',
    AutoClass: 'AutoClass',
    StringFormatMask: 'StringFormatMask',
    HasSecurity: 'HasSecurity',
    ReservedMask: 'ReservedMask',
    BeforeFieldInit: 'BeforeFieldInit',
    CustomFormatMask: 'CustomFormatMask'
} as const;

export type TypeAttributes = typeof TypeAttributes[keyof typeof TypeAttributes];


/**
 * 
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof TypeInfo
     */
    'customAttributes'?: Array<CustomAttributeData> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isCollectible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    'metadataToken'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isInterface'?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof TypeInfo
     */
    'memberType'?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'assemblyQualifiedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Assembly}
     * @memberof TypeInfo
     */
    'assembly'?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof TypeInfo
     */
    'module'?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNested'?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof TypeInfo
     */
    'declaringMethod'?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'underlyingSystemType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isTypeDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isByRef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isConstructedGenericType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericTypeParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericMethodParameter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericTypeDefinition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSZArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isVariableBoundArray'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isByRefLike'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isFunctionPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isUnmanagedFunctionPointer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'hasElementType'?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'genericTypeArguments'?: Array<Type> | null;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    'genericParameterPosition'?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof TypeInfo
     */
    'genericParameterAttributes'?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof TypeInfo
     */
    'attributes'?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAbstract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isImport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSealed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSpecialName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedAssembly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamANDAssem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamORAssem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedPrivate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNotPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAutoLayout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isExplicitLayout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isLayoutSequential'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAnsiClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAutoClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isUnicodeClass'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isCOMObject'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isContextful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isEnum'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isMarshalByRef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPrimitive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isValueType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSignatureType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecurityCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecuritySafeCritical'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecurityTransparent'?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof TypeInfo
     */
    'structLayoutAttribute'?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof TypeInfo
     */
    'typeInitializer'?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof TypeInfo
     */
    'typeHandle'?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'guid'?: string;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'baseType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     * @deprecated
     */
    'isSerializable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'containsGenericParameters'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'genericTypeParameters'?: Array<Type> | null;
    /**
     * 
     * @type {Array<ConstructorInfo>}
     * @memberof TypeInfo
     */
    'declaredConstructors'?: Array<ConstructorInfo> | null;
    /**
     * 
     * @type {Array<EventInfo>}
     * @memberof TypeInfo
     */
    'declaredEvents'?: Array<EventInfo> | null;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof TypeInfo
     */
    'declaredFields'?: Array<FieldInfo> | null;
    /**
     * 
     * @type {Array<MemberInfo>}
     * @memberof TypeInfo
     */
    'declaredMembers'?: Array<MemberInfo> | null;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof TypeInfo
     */
    'declaredMethods'?: Array<MethodInfo> | null;
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof TypeInfo
     */
    'declaredNestedTypes'?: Array<TypeInfo> | null;
    /**
     * 
     * @type {Array<PropertyInfo>}
     * @memberof TypeInfo
     */
    'declaredProperties'?: Array<PropertyInfo> | null;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'implementedInterfaces'?: Array<Type> | null;
}


/**
 * 
 * @export
 * @interface UnauthorizedObjectResult
 */
export interface UnauthorizedObjectResult {
    /**
     * 
     * @type {any}
     * @memberof UnauthorizedObjectResult
     */
    'value'?: any | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof UnauthorizedObjectResult
     */
    'formatters'?: Array<object> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnauthorizedObjectResult
     */
    'contentTypes'?: Array<string> | null;
    /**
     * 
     * @type {Type}
     * @memberof UnauthorizedObjectResult
     */
    'declaredType'?: Type;
    /**
     * 
     * @type {number}
     * @memberof UnauthorizedObjectResult
     */
    'statusCode'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateOrganisationViewModel
 */
export interface UpdateOrganisationViewModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganisationViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganisationViewModel
     */
    'userLimit'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionsForContactSyncDto
 */
export interface UpdateSubscriptionsForContactSyncDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateSubscriptionsForContactSyncDto
     */
    'subscriptionIdByUserId'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSubscriptionsForContactSyncDto
     */
    'contactsToDisable'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserAutocompleteDto
 */
export interface UserAutocompleteDto {
    /**
     * 
     * @type {string}
     * @memberof UserAutocompleteDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAutocompleteDto
     */
    'userName'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'organisationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'lockedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'mfaEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'lastLogin'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'isLoggedIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'subscriberAdministrator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'externalServicer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'isSelf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'contractualUser'?: boolean;
    /**
     * 
     * @type {InvitationState}
     * @memberof UserCreateDto
     */
    'invitationState'?: InvitationState;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'userLimitReached'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'applyClearlifeMfa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'confirmPassword'?: string | null;
}


/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'organisationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'lockedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'mfaEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastLogin'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isLoggedIn'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'subscriberAdministrator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'externalServicer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isSelf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'contractualUser'?: boolean;
    /**
     * 
     * @type {InvitationState}
     * @memberof UserDto
     */
    'invitationState'?: InvitationState;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'userLimitReached'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'applyClearlifeMfa'?: boolean;
}


/**
 * 
 * @export
 * @interface UserEditDto
 */
export interface UserEditDto {
    /**
     * 
     * @type {UserDto}
     * @memberof UserEditDto
     */
    'targetUser'?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditDto
     */
    'isUserSelf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditDto
     */
    'isSelfSystemAdministrator'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserEditDto
     */
    'organisationUserLimit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserEditDto
     */
    'activeContractualUsersCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserEditDto
     */
    'userLimitReached'?: boolean;
}
/**
 * 
 * @export
 * @interface UserEmailPreferenceDto
 */
export interface UserEmailPreferenceDto {
    /**
     * 
     * @type {string}
     * @memberof UserEmailPreferenceDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof UserEmailPreferenceDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEmailPreferenceDto
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface UserEmailPreferencesDto
 */
export interface UserEmailPreferencesDto {
    /**
     * 
     * @type {UserEmailPreferenceDto}
     * @memberof UserEmailPreferencesDto
     */
    'releaseEmailSubscriber': UserEmailPreferenceDto;
}
/**
 * 
 * @export
 * @interface UserInvitationDto
 */
export interface UserInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof UserInvitationDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationDto
     */
    'inviteCode'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInvitationDto
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserInviteCodeDto
 */
export interface UserInviteCodeDto {
    /**
     * 
     * @type {string}
     * @memberof UserInviteCodeDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInviteCodeDto
     */
    'inviteCode'?: string | null;
}

/**
 * ApiKeyApi - axios parameter creator
 * @export
 */
export const ApiKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimsForKeyMethodPOSTControllerApiKey: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey/CreateClaims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMethodPOSTControllerApiKey: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMethodDELETEControllerApiKey: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExistingMethodGETControllerApiKey: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey/GetExisting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccessMethodGETControllerApiKey: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApiKey/OpenAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeyApi - functional programming interface
 * @export
 */
export const ApiKeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiKeyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClaimsForKeyMethodPOSTControllerApiKey(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClaimsForKeyMethodPOSTControllerApiKey(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiKeyApi.createClaimsForKeyMethodPOSTControllerApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMethodPOSTControllerApiKey(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMethodPOSTControllerApiKey(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiKeyApi.createMethodPOSTControllerApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMethodDELETEControllerApiKey(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMethodDELETEControllerApiKey(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiKeyApi.deleteMethodDELETEControllerApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExistingMethodGETControllerApiKey(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExistingMethodGETControllerApiKey(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiKeyApi.getExistingMethodGETControllerApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenAccessMethodGETControllerApiKey(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenAccessMethodGETControllerApiKey(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiKeyApi.getOpenAccessMethodGETControllerApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApiKeyApi - factory interface
 * @export
 */
export const ApiKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiKeyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClaimsForKeyMethodPOSTControllerApiKey(body?: string, options?: any): AxiosPromise<ClaimDto> {
            return localVarFp.createClaimsForKeyMethodPOSTControllerApiKey(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMethodPOSTControllerApiKey(name?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createMethodPOSTControllerApiKey(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMethodDELETEControllerApiKey(name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMethodDELETEControllerApiKey(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExistingMethodGETControllerApiKey(options?: any): AxiosPromise<Array<ApiKey>> {
            return localVarFp.getExistingMethodGETControllerApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenAccessMethodGETControllerApiKey(options?: any): AxiosPromise<ClaimDto> {
            return localVarFp.getOpenAccessMethodGETControllerApiKey(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiKeyApi - object-oriented interface
 * @export
 * @class ApiKeyApi
 * @extends {BaseAPI}
 */
export class ApiKeyApi extends BaseAPI {
    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public createClaimsForKeyMethodPOSTControllerApiKey(body?: string, options?: RawAxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).createClaimsForKeyMethodPOSTControllerApiKey(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public createMethodPOSTControllerApiKey(name?: string, options?: RawAxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).createMethodPOSTControllerApiKey(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public deleteMethodDELETEControllerApiKey(name?: string, options?: RawAxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).deleteMethodDELETEControllerApiKey(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public getExistingMethodGETControllerApiKey(options?: RawAxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).getExistingMethodGETControllerApiKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public getOpenAccessMethodGETControllerApiKey(options?: RawAxiosRequestConfig) {
        return ApiKeyApiFp(this.configuration).getOpenAccessMethodGETControllerApiKey(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChangeDetailsApi - axios parameter creator
 * @export
 */
export const ChangeDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeDetailsViewModel} [changeDetailsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordMethodPOSTControllerChangeDetails: async (changeDetailsViewModel?: ChangeDetailsViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ChangeDetails/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeDetailsViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeDetailsApi - functional programming interface
 * @export
 */
export const ChangeDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeDetailsViewModel} [changeDetailsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel?: ChangeDetailsViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeDetailsApi.changePasswordMethodPOSTControllerChangeDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChangeDetailsApi - factory interface
 * @export
 */
export const ChangeDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeDetailsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeDetailsViewModel} [changeDetailsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel?: ChangeDetailsViewModel, options?: any): AxiosPromise<ChangePasswordResult> {
            return localVarFp.changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangeDetailsApi - object-oriented interface
 * @export
 * @class ChangeDetailsApi
 * @extends {BaseAPI}
 */
export class ChangeDetailsApi extends BaseAPI {
    /**
     * 
     * @param {ChangeDetailsViewModel} [changeDetailsViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeDetailsApi
     */
    public changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel?: ChangeDetailsViewModel, options?: RawAxiosRequestConfig) {
        return ChangeDetailsApiFp(this.configuration).changePasswordMethodPOSTControllerChangeDetails(changeDetailsViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConnectApi - axios parameter creator
 * @export
 */
export const ConnectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeCallbackMethodGETControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/Authorize/Callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeMethodGETControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/Authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endSessionMethodGETControllerConnect: async (error?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/EndSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutMethodGETControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenMethodPOSTControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/Token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoMethodGETControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoMethodPOSTControllerConnect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectApi - functional programming interface
 * @export
 */
export const ConnectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeCallbackMethodGETControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeCallbackMethodGETControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.authorizeCallbackMethodGETControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeMethodGETControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeMethodGETControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.authorizeMethodGETControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endSessionMethodGETControllerConnect(error?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endSessionMethodGETControllerConnect(error, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.endSessionMethodGETControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutMethodGETControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutMethodGETControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.logoutMethodGETControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenMethodPOSTControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenMethodPOSTControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.tokenMethodPOSTControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userinfoMethodGETControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userinfoMethodGETControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.userinfoMethodGETControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userinfoMethodPOSTControllerConnect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userinfoMethodPOSTControllerConnect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectApi.userinfoMethodPOSTControllerConnect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectApi - factory interface
 * @export
 */
export const ConnectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeCallbackMethodGETControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.authorizeCallbackMethodGETControllerConnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeMethodGETControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.authorizeMethodGETControllerConnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endSessionMethodGETControllerConnect(error?: string, options?: any): AxiosPromise<void> {
            return localVarFp.endSessionMethodGETControllerConnect(error, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutMethodGETControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.logoutMethodGETControllerConnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenMethodPOSTControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.tokenMethodPOSTControllerConnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoMethodGETControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.userinfoMethodGETControllerConnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userinfoMethodPOSTControllerConnect(options?: any): AxiosPromise<void> {
            return localVarFp.userinfoMethodPOSTControllerConnect(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectApi - object-oriented interface
 * @export
 * @class ConnectApi
 * @extends {BaseAPI}
 */
export class ConnectApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public authorizeCallbackMethodGETControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).authorizeCallbackMethodGETControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public authorizeMethodGETControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).authorizeMethodGETControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [error] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public endSessionMethodGETControllerConnect(error?: string, options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).endSessionMethodGETControllerConnect(error, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public logoutMethodGETControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).logoutMethodGETControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public tokenMethodPOSTControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).tokenMethodPOSTControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public userinfoMethodGETControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).userinfoMethodGETControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectApi
     */
    public userinfoMethodPOSTControllerConnect(options?: RawAxiosRequestConfig) {
        return ConnectApiFp(this.configuration).userinfoMethodPOSTControllerConnect(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckMethodGETControllerHealthCheck: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/HealthCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckMethodGETControllerHealthCheck(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckMethodGETControllerHealthCheck(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCheckApi.healthCheckMethodGETControllerHealthCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckMethodGETControllerHealthCheck(options?: any): AxiosPromise<void> {
            return localVarFp.healthCheckMethodGETControllerHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public healthCheckMethodGETControllerHealthCheck(options?: RawAxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).healthCheckMethodGETControllerHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InviteApi - axios parameter creator
 * @export
 */
export const InviteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {AcceptInvitationDto} [acceptInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationMethodPOSTControllerInvite: async (userId: string, acceptInvitationDto?: AcceptInvitationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('acceptInvitationMethodPOSTControllerInvite', 'userId', userId)
            const localVarPath = `/api/Invite/Accept/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitableUserMethodPOSTControllerInvite: async (userInvitationDto?: UserInvitationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invite/CreateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMethodDELETEControllerInvite: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMethodDELETEControllerInvite', 'userId', userId)
            const localVarPath = `/api/Invite/Delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveInviteByUserIdMethodGETControllerInvite: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('receiveInviteByUserIdMethodGETControllerInvite', 'userId', userId)
            const localVarPath = `/api/Invite/ReceiveInvite/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserInviteCodeDto} [userInviteCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveInviteMethodPOSTControllerInvite: async (userInviteCodeDto?: UserInviteCodeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invite/ReceiveInvite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInviteCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInviteMethodPOSTControllerInvite: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendInviteMethodPOSTControllerInvite', 'userId', userId)
            const localVarPath = `/api/Invite/Resend/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteEmailMethodPOSTControllerInvite: async (userInvitationDto?: UserInvitationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invite/SendEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InviteApi - functional programming interface
 * @export
 */
export const InviteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InviteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {AcceptInvitationDto} [acceptInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvitationMethodPOSTControllerInvite(userId: string, acceptInvitationDto?: AcceptInvitationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationCompleteViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvitationMethodPOSTControllerInvite(userId, acceptInvitationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.acceptInvitationMethodPOSTControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitableUserMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitableUserMethodPOSTControllerInvite(userInvitationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.createInvitableUserMethodPOSTControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMethodDELETEControllerInvite(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMethodDELETEControllerInvite(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.deleteMethodDELETEControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveInviteByUserIdMethodGETControllerInvite(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInviteCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveInviteByUserIdMethodGETControllerInvite(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.receiveInviteByUserIdMethodGETControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserInviteCodeDto} [userInviteCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveInviteMethodPOSTControllerInvite(userInviteCodeDto?: UserInviteCodeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptInvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveInviteMethodPOSTControllerInvite(userInviteCodeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.receiveInviteMethodPOSTControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInviteMethodPOSTControllerInvite(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendInviteMethodPOSTControllerInvite(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.resendInviteMethodPOSTControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInviteEmailMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInviteEmailMethodPOSTControllerInvite(userInvitationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InviteApi.sendInviteEmailMethodPOSTControllerInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InviteApi - factory interface
 * @export
 */
export const InviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InviteApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {AcceptInvitationDto} [acceptInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationMethodPOSTControllerInvite(userId: string, acceptInvitationDto?: AcceptInvitationDto, options?: any): AxiosPromise<RegistrationCompleteViewModel> {
            return localVarFp.acceptInvitationMethodPOSTControllerInvite(userId, acceptInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitableUserMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: any): AxiosPromise<string> {
            return localVarFp.createInvitableUserMethodPOSTControllerInvite(userInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMethodDELETEControllerInvite(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMethodDELETEControllerInvite(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveInviteByUserIdMethodGETControllerInvite(userId: string, options?: any): AxiosPromise<UserInviteCodeDto> {
            return localVarFp.receiveInviteByUserIdMethodGETControllerInvite(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserInviteCodeDto} [userInviteCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveInviteMethodPOSTControllerInvite(userInviteCodeDto?: UserInviteCodeDto, options?: any): AxiosPromise<AcceptInvitationDto> {
            return localVarFp.receiveInviteMethodPOSTControllerInvite(userInviteCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInviteMethodPOSTControllerInvite(userId: string, options?: any): AxiosPromise<UserInvitationDto> {
            return localVarFp.resendInviteMethodPOSTControllerInvite(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserInvitationDto} [userInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteEmailMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendInviteEmailMethodPOSTControllerInvite(userInvitationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InviteApi - object-oriented interface
 * @export
 * @class InviteApi
 * @extends {BaseAPI}
 */
export class InviteApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {AcceptInvitationDto} [acceptInvitationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public acceptInvitationMethodPOSTControllerInvite(userId: string, acceptInvitationDto?: AcceptInvitationDto, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).acceptInvitationMethodPOSTControllerInvite(userId, acceptInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserInvitationDto} [userInvitationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public createInvitableUserMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).createInvitableUserMethodPOSTControllerInvite(userInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public deleteMethodDELETEControllerInvite(userId: string, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).deleteMethodDELETEControllerInvite(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public receiveInviteByUserIdMethodGETControllerInvite(userId: string, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).receiveInviteByUserIdMethodGETControllerInvite(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserInviteCodeDto} [userInviteCodeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public receiveInviteMethodPOSTControllerInvite(userInviteCodeDto?: UserInviteCodeDto, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).receiveInviteMethodPOSTControllerInvite(userInviteCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public resendInviteMethodPOSTControllerInvite(userId: string, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).resendInviteMethodPOSTControllerInvite(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserInvitationDto} [userInvitationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public sendInviteEmailMethodPOSTControllerInvite(userInvitationDto?: UserInvitationDto, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).sendInviteEmailMethodPOSTControllerInvite(userInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [applicationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationHomeMethodGETControllerLogin: async (applicationName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/ApplicationHome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (applicationName !== undefined) {
                localVarQueryParameter['applicationName'] = applicationName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMethodGETControllerLogin: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/Check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmIdentityViewModel} [confirmIdentityViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmIdentityMethodPOSTControllerLogin: async (confirmIdentityViewModel?: ConfirmIdentityViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/ConfirmIdentity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmIdentityViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgottenPasswordViewModel} [forgottenPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordMethodPOSTControllerLogin: async (forgottenPasswordViewModel?: ForgottenPasswordViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgottenUsernameViewModel} [forgottenUsernameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotUsernameMethodPOSTControllerLogin: async (forgottenUsernameViewModel?: ForgottenUsernameViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/ForgotUsername`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenUsernameViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginMethodGETControllerLogin: async (returnUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethodPOSTControllerLogin: async (loginViewModel?: LoginViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Login/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [applicationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationHomeMethodGETControllerLogin(applicationName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationHomeMethodGETControllerLogin(applicationName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.applicationHomeMethodGETControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMethodGETControllerLogin(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMethodGETControllerLogin(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.checkMethodGETControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ConfirmIdentityViewModel} [confirmIdentityViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel?: ConfirmIdentityViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.confirmIdentityMethodPOSTControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForgottenPasswordViewModel} [forgottenPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel?: ForgottenPasswordViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.forgotPasswordMethodPOSTControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForgottenUsernameViewModel} [forgottenUsernameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel?: ForgottenUsernameViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginReminderData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.forgotUsernameMethodPOSTControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginMethodGETControllerLogin(returnUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginMethodGETControllerLogin(returnUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.getLoginMethodGETControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginMethodPOSTControllerLogin(loginViewModel?: LoginViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginMethodPOSTControllerLogin(loginViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginMethodPOSTControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [applicationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationHomeMethodGETControllerLogin(applicationName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.applicationHomeMethodGETControllerLogin(applicationName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMethodGETControllerLogin(options?: any): AxiosPromise<string> {
            return localVarFp.checkMethodGETControllerLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmIdentityViewModel} [confirmIdentityViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel?: ConfirmIdentityViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgottenPasswordViewModel} [forgottenPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel?: ForgottenPasswordViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgottenUsernameViewModel} [forgottenUsernameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel?: ForgottenUsernameViewModel, options?: any): AxiosPromise<LoginReminderData> {
            return localVarFp.forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginMethodGETControllerLogin(returnUrl?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getLoginMethodGETControllerLogin(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethodPOSTControllerLogin(loginViewModel?: LoginViewModel, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.loginMethodPOSTControllerLogin(loginViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @param {string} [applicationName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public applicationHomeMethodGETControllerLogin(applicationName?: string, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).applicationHomeMethodGETControllerLogin(applicationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public checkMethodGETControllerLogin(options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).checkMethodGETControllerLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfirmIdentityViewModel} [confirmIdentityViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel?: ConfirmIdentityViewModel, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).confirmIdentityMethodPOSTControllerLogin(confirmIdentityViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgottenPasswordViewModel} [forgottenPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel?: ForgottenPasswordViewModel, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).forgotPasswordMethodPOSTControllerLogin(forgottenPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgottenUsernameViewModel} [forgottenUsernameViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel?: ForgottenUsernameViewModel, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).forgotUsernameMethodPOSTControllerLogin(forgottenUsernameViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public getLoginMethodGETControllerLogin(returnUrl?: string, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).getLoginMethodGETControllerLogin(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginViewModel} [loginViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginMethodPOSTControllerLogin(loginViewModel?: LoginViewModel, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginMethodPOSTControllerLogin(loginViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetaApi - axios parameter creator
 * @export
 */
export const MetaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endpointsMethodGETControllerMeta: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Meta/Endpoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endpointsMethodGETControllerMeta(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndpointsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endpointsMethodGETControllerMeta(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaApi.endpointsMethodGETControllerMeta']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endpointsMethodGETControllerMeta(options?: any): AxiosPromise<EndpointsDto> {
            return localVarFp.endpointsMethodGETControllerMeta(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public endpointsMethodGETControllerMeta(options?: RawAxiosRequestConfig) {
        return MetaApiFp(this.configuration).endpointsMethodGETControllerMeta(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MfaApi - axios parameter creator
 * @export
 */
export const MfaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableMethodPOSTControllerMfa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableByIdMethodPOSTControllerMfa: async (userId: string, mfaRegistrationDto?: MfaRegistrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('enableByIdMethodPOSTControllerMfa', 'userId', userId)
            const localVarPath = `/api/Mfa/Enable/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSelfMethodPOSTControllerMfa: async (mfaRegistrationDto?: MfaRegistrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnableSelfMethodGETControllerMfa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResetForOrganizationDefaultMethodGETControllerMfa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/OrganizationDefaultReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethodPOSTControllerMfa: async (mfaViewModel?: MfaViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoveryByUserIdMethodPOSTControllerMfa: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('recoveryByUserIdMethodPOSTControllerMfa', 'userId', userId)
            const localVarPath = `/api/Mfa/Recovery/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoveryMethodPOSTControllerMfa: async (mfaViewModel?: MfaViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/RecoveryCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverySelfMethodPOSTControllerMfa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Recovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetForOrganizationDefaultMethodPOSTControllerMfa: async (mfaRegistrationDto?: MfaRegistrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/OrganizationDefaultReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mfaRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusByUserIdMethodGETControllerMfa: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('statusByUserIdMethodGETControllerMfa', 'userId', userId)
            const localVarPath = `/api/Mfa/Status/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusMethodGETControllerMfa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Mfa/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MfaApi - functional programming interface
 * @export
 */
export const MfaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MfaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableMethodPOSTControllerMfa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableMethodPOSTControllerMfa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.disableMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableByIdMethodPOSTControllerMfa(userId: string, mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableByIdMethodPOSTControllerMfa(userId, mfaRegistrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.enableByIdMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableSelfMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableSelfMethodPOSTControllerMfa(mfaRegistrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.enableSelfMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnableSelfMethodGETControllerMfa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaRegistrationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnableSelfMethodGETControllerMfa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.getEnableSelfMethodGETControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResetForOrganizationDefaultMethodGETControllerMfa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaStatusViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResetForOrganizationDefaultMethodGETControllerMfa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.getResetForOrganizationDefaultMethodGETControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginMethodPOSTControllerMfa(mfaViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.loginMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoveryByUserIdMethodPOSTControllerMfa(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaRecoveryCodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoveryByUserIdMethodPOSTControllerMfa(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.recoveryByUserIdMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoveryMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoveryMethodPOSTControllerMfa(mfaViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.recoveryMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverySelfMethodPOSTControllerMfa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaRecoveryCodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverySelfMethodPOSTControllerMfa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.recoverySelfMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.resetForOrganizationDefaultMethodPOSTControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusByUserIdMethodGETControllerMfa(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaStatusViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusByUserIdMethodGETControllerMfa(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.statusByUserIdMethodGETControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusMethodGETControllerMfa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaStatusViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusMethodGETControllerMfa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MfaApi.statusMethodGETControllerMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MfaApi - factory interface
 * @export
 */
export const MfaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MfaApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableMethodPOSTControllerMfa(options?: any): AxiosPromise<void> {
            return localVarFp.disableMethodPOSTControllerMfa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableByIdMethodPOSTControllerMfa(userId: string, mfaRegistrationDto?: MfaRegistrationDto, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.enableByIdMethodPOSTControllerMfa(userId, mfaRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableSelfMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.enableSelfMethodPOSTControllerMfa(mfaRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnableSelfMethodGETControllerMfa(options?: any): AxiosPromise<MfaRegistrationViewModel> {
            return localVarFp.getEnableSelfMethodGETControllerMfa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResetForOrganizationDefaultMethodGETControllerMfa(options?: any): AxiosPromise<MfaStatusViewModel> {
            return localVarFp.getResetForOrganizationDefaultMethodGETControllerMfa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.loginMethodPOSTControllerMfa(mfaViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoveryByUserIdMethodPOSTControllerMfa(userId: string, options?: any): AxiosPromise<MfaRecoveryCodes> {
            return localVarFp.recoveryByUserIdMethodPOSTControllerMfa(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MfaViewModel} [mfaViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoveryMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.recoveryMethodPOSTControllerMfa(mfaViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverySelfMethodPOSTControllerMfa(options?: any): AxiosPromise<MfaRecoveryCodes> {
            return localVarFp.recoverySelfMethodPOSTControllerMfa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MfaRegistrationDto} [mfaRegistrationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusByUserIdMethodGETControllerMfa(userId: string, options?: any): AxiosPromise<MfaStatusViewModel> {
            return localVarFp.statusByUserIdMethodGETControllerMfa(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusMethodGETControllerMfa(options?: any): AxiosPromise<MfaStatusViewModel> {
            return localVarFp.statusMethodGETControllerMfa(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MfaApi - object-oriented interface
 * @export
 * @class MfaApi
 * @extends {BaseAPI}
 */
export class MfaApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public disableMethodPOSTControllerMfa(options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).disableMethodPOSTControllerMfa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {MfaRegistrationDto} [mfaRegistrationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public enableByIdMethodPOSTControllerMfa(userId: string, mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).enableByIdMethodPOSTControllerMfa(userId, mfaRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MfaRegistrationDto} [mfaRegistrationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public enableSelfMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).enableSelfMethodPOSTControllerMfa(mfaRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public getEnableSelfMethodGETControllerMfa(options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).getEnableSelfMethodGETControllerMfa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public getResetForOrganizationDefaultMethodGETControllerMfa(options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).getResetForOrganizationDefaultMethodGETControllerMfa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MfaViewModel} [mfaViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public loginMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).loginMethodPOSTControllerMfa(mfaViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public recoveryByUserIdMethodPOSTControllerMfa(userId: string, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).recoveryByUserIdMethodPOSTControllerMfa(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MfaViewModel} [mfaViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public recoveryMethodPOSTControllerMfa(mfaViewModel?: MfaViewModel, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).recoveryMethodPOSTControllerMfa(mfaViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public recoverySelfMethodPOSTControllerMfa(options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).recoverySelfMethodPOSTControllerMfa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MfaRegistrationDto} [mfaRegistrationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto?: MfaRegistrationDto, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).resetForOrganizationDefaultMethodPOSTControllerMfa(mfaRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public statusByUserIdMethodGETControllerMfa(userId: string, options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).statusByUserIdMethodGETControllerMfa(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MfaApi
     */
    public statusMethodGETControllerMfa(options?: RawAxiosRequestConfig) {
        return MfaApiFp(this.configuration).statusMethodGETControllerMfa(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OfficeApi - axios parameter creator
 * @export
 */
export const OfficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [machineId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateMethodPOSTControllerOffice: async (username?: string, password?: string, machineId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Office`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (username !== undefined) { 
                localVarFormParams.append('Username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('Password', password as any);
            }
    
            if (machineId !== undefined) { 
                localVarFormParams.append('MachineId', machineId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficeApi - functional programming interface
 * @export
 */
export const OfficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [machineId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateMethodPOSTControllerOffice(username?: string, password?: string, machineId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateMethodPOSTControllerOffice(username, password, machineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfficeApi.authenticateMethodPOSTControllerOffice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OfficeApi - factory interface
 * @export
 */
export const OfficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [machineId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateMethodPOSTControllerOffice(username?: string, password?: string, machineId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.authenticateMethodPOSTControllerOffice(username, password, machineId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficeApi - object-oriented interface
 * @export
 * @class OfficeApi
 * @extends {BaseAPI}
 */
export class OfficeApi extends BaseAPI {
    /**
     * 
     * @param {string} [username] 
     * @param {string} [password] 
     * @param {string} [machineId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeApi
     */
    public authenticateMethodPOSTControllerOffice(username?: string, password?: string, machineId?: string, options?: RawAxiosRequestConfig) {
        return OfficeApiFp(this.configuration).authenticateMethodPOSTControllerOffice(username, password, machineId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationsApi - axios parameter creator
 * @export
 */
export const OrganisationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMethodGETControllerOrganisations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<Filter>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrganisationsMethodPOSTControllerOrganisations: async (filter?: Array<Filter>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisations/FindOrganisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaRequiredMethodGETControllerOrganisations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisations/MfaRequired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerMethodPOSTControllerOrganisations: async (publicId: string, organisationName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('registerMethodPOSTControllerOrganisations', 'publicId', publicId)
            // verify required parameter 'organisationName' is not null or undefined
            assertParamExists('registerMethodPOSTControllerOrganisations', 'organisationName', organisationName)
            const localVarPath = `/api/Organisations/Register/{publicId}/{organisationName}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)))
                .replace(`{${"organisationName"}}`, encodeURIComponent(String(organisationName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMfaRequiredMethodPUTControllerOrganisations: async (required: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'required' is not null or undefined
            assertParamExists('setMfaRequiredMethodPUTControllerOrganisations', 'required', required)
            const localVarPath = `/api/Organisations/MfaRequired/{required}`
                .replace(`{${"required"}}`, encodeURIComponent(String(required)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {UpdateOrganisationViewModel} [updateOrganisationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistrationMethodPUTControllerOrganisations: async (publicId: string, updateOrganisationViewModel?: UpdateOrganisationViewModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('updateRegistrationMethodPUTControllerOrganisations', 'publicId', publicId)
            const localVarPath = `/api/Organisations/Update/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganisationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationsApi - functional programming interface
 * @export
 */
export const OrganisationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMethodGETControllerOrganisations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMethodGETControllerOrganisations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.allMethodGETControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<Filter>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOrganisationsMethodPOSTControllerOrganisations(filter?: Array<Filter>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOrganisationsMethodPOSTControllerOrganisations(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.findOrganisationsMethodPOSTControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMfaRequiredMethodGETControllerOrganisations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMfaRequiredMethodGETControllerOrganisations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.getMfaRequiredMethodGETControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerMethodPOSTControllerOrganisations(publicId: string, organisationName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerMethodPOSTControllerOrganisations(publicId, organisationName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.registerMethodPOSTControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMfaRequiredMethodPUTControllerOrganisations(required: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMfaRequiredMethodPUTControllerOrganisations(required, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.setMfaRequiredMethodPUTControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {UpdateOrganisationViewModel} [updateOrganisationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegistrationMethodPUTControllerOrganisations(publicId: string, updateOrganisationViewModel?: UpdateOrganisationViewModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegistrationMethodPUTControllerOrganisations(publicId, updateOrganisationViewModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationsApi.updateRegistrationMethodPUTControllerOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganisationsApi - factory interface
 * @export
 */
export const OrganisationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMethodGETControllerOrganisations(options?: any): AxiosPromise<Array<OrganisationDto>> {
            return localVarFp.allMethodGETControllerOrganisations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<Filter>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrganisationsMethodPOSTControllerOrganisations(filter?: Array<Filter>, options?: any): AxiosPromise<Array<OrganisationDto>> {
            return localVarFp.findOrganisationsMethodPOSTControllerOrganisations(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaRequiredMethodGETControllerOrganisations(options?: any): AxiosPromise<boolean> {
            return localVarFp.getMfaRequiredMethodGETControllerOrganisations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {string} organisationName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerMethodPOSTControllerOrganisations(publicId: string, organisationName: string, options?: any): AxiosPromise<void> {
            return localVarFp.registerMethodPOSTControllerOrganisations(publicId, organisationName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMfaRequiredMethodPUTControllerOrganisations(required: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.setMfaRequiredMethodPUTControllerOrganisations(required, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {UpdateOrganisationViewModel} [updateOrganisationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistrationMethodPUTControllerOrganisations(publicId: string, updateOrganisationViewModel?: UpdateOrganisationViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateRegistrationMethodPUTControllerOrganisations(publicId, updateOrganisationViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationsApi - object-oriented interface
 * @export
 * @class OrganisationsApi
 * @extends {BaseAPI}
 */
export class OrganisationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public allMethodGETControllerOrganisations(options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).allMethodGETControllerOrganisations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<Filter>} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public findOrganisationsMethodPOSTControllerOrganisations(filter?: Array<Filter>, options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).findOrganisationsMethodPOSTControllerOrganisations(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public getMfaRequiredMethodGETControllerOrganisations(options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).getMfaRequiredMethodGETControllerOrganisations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {string} organisationName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public registerMethodPOSTControllerOrganisations(publicId: string, organisationName: string, options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).registerMethodPOSTControllerOrganisations(publicId, organisationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} required 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public setMfaRequiredMethodPUTControllerOrganisations(required: boolean, options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).setMfaRequiredMethodPUTControllerOrganisations(required, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {UpdateOrganisationViewModel} [updateOrganisationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationsApi
     */
    public updateRegistrationMethodPUTControllerOrganisations(publicId: string, updateOrganisationViewModel?: UpdateOrganisationViewModel, options?: RawAxiosRequestConfig) {
        return OrganisationsApiFp(this.configuration).updateRegistrationMethodPUTControllerOrganisations(publicId, updateOrganisationViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userName 
         * @param {RoleChangeDto} [roleChangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRolesMethodPOSTControllerRoles: async (userName: string, roleChangeDto?: RoleChangeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('changeUserRolesMethodPOSTControllerRoles', 'userName', userName)
            const localVarPath = `/api/Roles/User/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMethodGETControllerRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnRolesMethodGETControllerRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Roles/Self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRolesMethodGETControllerRoles: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getUserRolesMethodGETControllerRoles', 'userName', userName)
            const localVarPath = `/api/Roles/User/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {RoleChangeDto} [roleChangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserRolesMethodPOSTControllerRoles(userName: string, roleChangeDto?: RoleChangeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserRolesMethodPOSTControllerRoles(userName, roleChangeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.changeUserRolesMethodPOSTControllerRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMethodGETControllerRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMethodGETControllerRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getAllMethodGETControllerRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnRolesMethodGETControllerRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnRolesMethodGETControllerRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getOwnRolesMethodGETControllerRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRolesMethodGETControllerRoles(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRolesMethodGETControllerRoles(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getUserRolesMethodGETControllerRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {RoleChangeDto} [roleChangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRolesMethodPOSTControllerRoles(userName: string, roleChangeDto?: RoleChangeDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserRolesMethodPOSTControllerRoles(userName, roleChangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMethodGETControllerRoles(options?: any): AxiosPromise<Array<ModuleDto>> {
            return localVarFp.getAllMethodGETControllerRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnRolesMethodGETControllerRoles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getOwnRolesMethodGETControllerRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRolesMethodGETControllerRoles(userName: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getUserRolesMethodGETControllerRoles(userName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {string} userName 
     * @param {RoleChangeDto} [roleChangeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public changeUserRolesMethodPOSTControllerRoles(userName: string, roleChangeDto?: RoleChangeDto, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).changeUserRolesMethodPOSTControllerRoles(userName, roleChangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getAllMethodGETControllerRoles(options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getAllMethodGETControllerRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getOwnRolesMethodGETControllerRoles(options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getOwnRolesMethodGETControllerRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getUserRolesMethodGETControllerRoles(userName: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getUserRolesMethodGETControllerRoles(userName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserRolesApi - axios parameter creator
 * @export
 */
export const UserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesForAdminMethodGETControllerUserRoles: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getRolesForAdminMethodGETControllerUserRoles', 'userName', userName)
            const localVarPath = `/api/UserRoles/Administration/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesMethodGETControllerUserRoles: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getRolesMethodGETControllerUserRoles', 'userName', userName)
            const localVarPath = `/api/UserRoles/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleName 
         * @param {string} organisationPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInOrganisationAndRoleMethodGETControllerUserRoles: async (roleName: string, organisationPublicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleName' is not null or undefined
            assertParamExists('getUsersInOrganisationAndRoleMethodGETControllerUserRoles', 'roleName', roleName)
            // verify required parameter 'organisationPublicId' is not null or undefined
            assertParamExists('getUsersInOrganisationAndRoleMethodGETControllerUserRoles', 'organisationPublicId', organisationPublicId)
            const localVarPath = `/api/UserRoles/UsersInOrganisationAndRole/{roleName}/{organisationPublicId}`
                .replace(`{${"roleName"}}`, encodeURIComponent(String(roleName)))
                .replace(`{${"organisationPublicId"}}`, encodeURIComponent(String(organisationPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInRoleMethodGETControllerUserRoles: async (roleName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleName' is not null or undefined
            assertParamExists('getUsersInRoleMethodGETControllerUserRoles', 'roleName', roleName)
            const localVarPath = `/api/UserRoles/UsersInRole/{roleName}`
                .replace(`{${"roleName"}}`, encodeURIComponent(String(roleName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSelfAssignedWithRoleMethodGETControllerUserRoles: async (role?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserRoles/IsSelfAssignedWithRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesApi - functional programming interface
 * @export
 */
export const UserRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolesForAdminMethodGETControllerUserRoles(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Claim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolesForAdminMethodGETControllerUserRoles(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesApi.getRolesForAdminMethodGETControllerUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolesMethodGETControllerUserRoles(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Claim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolesMethodGETControllerUserRoles(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesApi.getRolesMethodGETControllerUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roleName 
         * @param {string} organisationPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName: string, organisationPublicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName, organisationPublicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesApi.getUsersInOrganisationAndRoleMethodGETControllerUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInRoleMethodGETControllerUserRoles(roleName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersInRoleMethodGETControllerUserRoles(roleName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesApi.getUsersInRoleMethodGETControllerUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isSelfAssignedWithRoleMethodGETControllerUserRoles(role?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isSelfAssignedWithRoleMethodGETControllerUserRoles(role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRolesApi.isSelfAssignedWithRoleMethodGETControllerUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserRolesApi - factory interface
 * @export
 */
export const UserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesForAdminMethodGETControllerUserRoles(userName: string, options?: any): AxiosPromise<Array<Claim>> {
            return localVarFp.getRolesForAdminMethodGETControllerUserRoles(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesMethodGETControllerUserRoles(userName: string, options?: any): AxiosPromise<Array<Claim>> {
            return localVarFp.getRolesMethodGETControllerUserRoles(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roleName 
         * @param {string} organisationPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName: string, organisationPublicId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName, organisationPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInRoleMethodGETControllerUserRoles(roleName: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getUsersInRoleMethodGETControllerUserRoles(roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [role] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSelfAssignedWithRoleMethodGETControllerUserRoles(role?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isSelfAssignedWithRoleMethodGETControllerUserRoles(role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesApi - object-oriented interface
 * @export
 * @class UserRolesApi
 * @extends {BaseAPI}
 */
export class UserRolesApi extends BaseAPI {
    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public getRolesForAdminMethodGETControllerUserRoles(userName: string, options?: RawAxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).getRolesForAdminMethodGETControllerUserRoles(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public getRolesMethodGETControllerUserRoles(userName: string, options?: RawAxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).getRolesMethodGETControllerUserRoles(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roleName 
     * @param {string} organisationPublicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName: string, organisationPublicId: string, options?: RawAxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).getUsersInOrganisationAndRoleMethodGETControllerUserRoles(roleName, organisationPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public getUsersInRoleMethodGETControllerUserRoles(roleName: string, options?: RawAxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).getUsersInRoleMethodGETControllerUserRoles(roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [role] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public isSelfAssignedWithRoleMethodGETControllerUserRoles(role?: string, options?: RawAxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).isSelfAssignedWithRoleMethodGETControllerUserRoles(role, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/All`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditReportMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/AuditReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkApprovedMethodGETControllerUsers: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('checkApprovedMethodGETControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/CheckApproved/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserMethodPOSTControllerUsers: async (userCreateDto?: UserCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeClearLifeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveUserIdsMethodGETControllerUsers: async (includeClearLifeUsers?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/ActiveUserIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeClearLifeUsers !== undefined) {
                localVarQueryParameter['includeClearLifeUsers'] = includeClearLifeUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedUsersForOrganisationMethodGETControllerUsers: async (clientPublicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientPublicId' is not null or undefined
            assertParamExists('getApprovedUsersForOrganisationMethodGETControllerUsers', 'clientPublicId', clientPublicId)
            const localVarPath = `/api/Users/CheckApproved/Organization/{clientPublicId}`
                .replace(`{${"clientPublicId"}}`, encodeURIComponent(String(clientPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedUsersMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/CheckApproved`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClearLifeUserIdsMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/ClearLifeUserIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailPreferencesMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/EmailPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpersonatedUserMethodGETControllerUsers: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getImpersonatedUserMethodGETControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/Impersonate/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUIFontSizeMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Self/UIFontSize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMethodGETControllerUsers: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getUserMethodGETControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/User/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSelfMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForContactSyncMethodGETControllerUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserMethodPATCHControllerUsers: async (userName: string, userDto?: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('patchUserMethodPATCHControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/User/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAuthenticationMethodPUTControllerUsers: async (userName: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('resetAuthenticationMethodPUTControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/ResetAuthentication/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [partialName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMethodGETControllerUsers: async (partialName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEmailPreferencesDto} [userEmailPreferencesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailPreferencesMethodPOSTControllerUsers: async (userEmailPreferencesDto?: UserEmailPreferencesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/EmailPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEmailPreferencesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fontSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUIFontSizeMethodPATCHControllerUsers: async (fontSize: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fontSize' is not null or undefined
            assertParamExists('setUIFontSizeMethodPATCHControllerUsers', 'fontSize', fontSize)
            const localVarPath = `/api/Users/Self/UIFontSize/{fontSize}`
                .replace(`{${"fontSize"}}`, encodeURIComponent(String(fontSize)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeOwnershipMethodGETControllerUsers: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('takeOwnershipMethodGETControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/TakeOwnership/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUserMethodPUTControllerUsers: async (userName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('unlockUserMethodPUTControllerUsers', 'userName', userName)
            const localVarPath = `/api/Users/Unlock/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSubscriptionsForContactSyncDto} [updateSubscriptionsForContactSyncDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionsForContactSyncMethodPUTControllerUsers: async (updateSubscriptionsForContactSyncDto?: UpdateSubscriptionsForContactSyncDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionsForContactSyncDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMethodPUTControllerUsers: async (userName: string, enabled: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('updateUserMethodPUTControllerUsers', 'userName', userName)
            // verify required parameter 'enabled' is not null or undefined
            assertParamExists('updateUserMethodPUTControllerUsers', 'enabled', enabled)
            const localVarPath = `/api/Users/Enable/{userName}/{enabled}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)))
                .replace(`{${"enabled"}}`, encodeURIComponent(String(enabled)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.allMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditReportMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditReportMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.auditReportMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkApprovedMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkApprovedMethodGETControllerUsers(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.checkApprovedMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserMethodPOSTControllerUsers(userCreateDto?: UserCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserMethodPOSTControllerUsers(userCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUserMethodPOSTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [includeClearLifeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getActiveUserIdsMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clientPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getApprovedUsersForOrganisationMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApprovedUsersMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApprovedUsersMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getApprovedUsersMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClearLifeUserIdsMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClearLifeUserIdsMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getClearLifeUserIdsMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailPreferencesMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmailPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailPreferencesMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getEmailPreferencesMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpersonatedUserMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpersonatedUserMethodGETControllerUsers(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getImpersonatedUserMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUIFontSizeMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUIFontSizeMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUIFontSizeMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMethodGETControllerUsers(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSelfMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSelfMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserSelfMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersForContactSyncMethodGETControllerUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactSyncUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersForContactSyncMethodGETControllerUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsersForContactSyncMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserMethodPATCHControllerUsers(userName: string, userDto?: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserMethodPATCHControllerUsers(userName, userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUserMethodPATCHControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAuthenticationMethodPUTControllerUsers(userName: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAuthenticationMethodPUTControllerUsers(userName, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resetAuthenticationMethodPUTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [partialName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMethodGETControllerUsers(partialName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAutocompleteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMethodGETControllerUsers(partialName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.searchMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserEmailPreferencesDto} [userEmailPreferencesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto?: UserEmailPreferencesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.setEmailPreferencesMethodPOSTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fontSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUIFontSizeMethodPATCHControllerUsers(fontSize: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUIFontSizeMethodPATCHControllerUsers(fontSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.setUIFontSizeMethodPATCHControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeOwnershipMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeOwnershipMethodGETControllerUsers(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.takeOwnershipMethodGETControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockUserMethodPUTControllerUsers(userName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockUserMethodPUTControllerUsers(userName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.unlockUserMethodPUTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateSubscriptionsForContactSyncDto} [updateSubscriptionsForContactSyncDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto?: UpdateSubscriptionsForContactSyncDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateSubscriptionsForContactSyncMethodPUTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userName 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserMethodPUTControllerUsers(userName: string, enabled: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserMethodPUTControllerUsers(userName, enabled, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUserMethodPUTControllerUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMethodGETControllerUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.allMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditReportMethodGETControllerUsers(options?: any): AxiosPromise<void> {
            return localVarFp.auditReportMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkApprovedMethodGETControllerUsers(userName: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkApprovedMethodGETControllerUsers(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserMethodPOSTControllerUsers(userCreateDto?: UserCreateDto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserMethodPOSTControllerUsers(userCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeClearLifeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers?: boolean, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovedUsersMethodGETControllerUsers(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getApprovedUsersMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClearLifeUserIdsMethodGETControllerUsers(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getClearLifeUserIdsMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailPreferencesMethodGETControllerUsers(options?: any): AxiosPromise<UserEmailPreferencesDto> {
            return localVarFp.getEmailPreferencesMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpersonatedUserMethodGETControllerUsers(userName: string, options?: any): AxiosPromise<ClaimDto> {
            return localVarFp.getImpersonatedUserMethodGETControllerUsers(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUIFontSizeMethodGETControllerUsers(options?: any): AxiosPromise<void> {
            return localVarFp.getUIFontSizeMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMethodGETControllerUsers(userName: string, options?: any): AxiosPromise<UserEditDto> {
            return localVarFp.getUserMethodGETControllerUsers(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSelfMethodGETControllerUsers(options?: any): AxiosPromise<UserEditDto> {
            return localVarFp.getUserSelfMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForContactSyncMethodGETControllerUsers(options?: any): AxiosPromise<Array<ContactSyncUser>> {
            return localVarFp.getUsersForContactSyncMethodGETControllerUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserMethodPATCHControllerUsers(userName: string, userDto?: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.patchUserMethodPATCHControllerUsers(userName, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAuthenticationMethodPUTControllerUsers(userName: string, type?: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetAuthenticationMethodPUTControllerUsers(userName, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [partialName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMethodGETControllerUsers(partialName?: string, options?: any): AxiosPromise<Array<UserAutocompleteDto>> {
            return localVarFp.searchMethodGETControllerUsers(partialName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEmailPreferencesDto} [userEmailPreferencesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto?: UserEmailPreferencesDto, options?: any): AxiosPromise<void> {
            return localVarFp.setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fontSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUIFontSizeMethodPATCHControllerUsers(fontSize: string, options?: any): AxiosPromise<void> {
            return localVarFp.setUIFontSizeMethodPATCHControllerUsers(fontSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeOwnershipMethodGETControllerUsers(userName: string, options?: any): AxiosPromise<string> {
            return localVarFp.takeOwnershipMethodGETControllerUsers(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUserMethodPUTControllerUsers(userName: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockUserMethodPUTControllerUsers(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSubscriptionsForContactSyncDto} [updateSubscriptionsForContactSyncDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto?: UpdateSubscriptionsForContactSyncDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMethodPUTControllerUsers(userName: string, enabled: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserMethodPUTControllerUsers(userName, enabled, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public allMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).allMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public auditReportMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).auditReportMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public checkApprovedMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).checkApprovedMethodGETControllerUsers(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateDto} [userCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserMethodPOSTControllerUsers(userCreateDto?: UserCreateDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserMethodPOSTControllerUsers(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeClearLifeUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers?: boolean, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getActiveUserIdsMethodGETControllerUsers(includeClearLifeUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientPublicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApprovedUsersForOrganisationMethodGETControllerUsers(clientPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApprovedUsersMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApprovedUsersMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getClearLifeUserIdsMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getClearLifeUserIdsMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEmailPreferencesMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEmailPreferencesMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getImpersonatedUserMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getImpersonatedUserMethodGETControllerUsers(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUIFontSizeMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUIFontSizeMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserMethodGETControllerUsers(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserSelfMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserSelfMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersForContactSyncMethodGETControllerUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersForContactSyncMethodGETControllerUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUserMethodPATCHControllerUsers(userName: string, userDto?: UserDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUserMethodPATCHControllerUsers(userName, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetAuthenticationMethodPUTControllerUsers(userName: string, type?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetAuthenticationMethodPUTControllerUsers(userName, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [partialName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchMethodGETControllerUsers(partialName?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).searchMethodGETControllerUsers(partialName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEmailPreferencesDto} [userEmailPreferencesDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto?: UserEmailPreferencesDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).setEmailPreferencesMethodPOSTControllerUsers(userEmailPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fontSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setUIFontSizeMethodPATCHControllerUsers(fontSize: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).setUIFontSizeMethodPATCHControllerUsers(fontSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public takeOwnershipMethodGETControllerUsers(userName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).takeOwnershipMethodGETControllerUsers(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public unlockUserMethodPUTControllerUsers(userName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).unlockUserMethodPUTControllerUsers(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSubscriptionsForContactSyncDto} [updateSubscriptionsForContactSyncDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto?: UpdateSubscriptionsForContactSyncDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateSubscriptionsForContactSyncMethodPUTControllerUsers(updateSubscriptionsForContactSyncDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {boolean} enabled 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserMethodPUTControllerUsers(userName: string, enabled: boolean, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserMethodPUTControllerUsers(userName, enabled, options).then((request) => request(this.axios, this.basePath));
    }
}



