
import * as Sentry from "@sentry/vue";
import type { SentryConfigurationOptions } from "../swagger-gen/auth";
import { isEmpty, isNil } from "lodash";
import Vue from "vue";

/**
 * Sets up sentry
 */
export function setUpSentry() {

  const sentryOptions = (window as unknown as { sentry: SentryConfigurationOptions }).sentry;
  if (isNil(sentryOptions)) return;

  const environment = sentryOptions.environment || "unknown";
  const enableSentry = sentryOptions.enabled && !isEmpty(sentryOptions.dsn);

  const configuration = {
    Vue,
    environment,
    dsn: sentryOptions.dsn || "",
    tracePropagationTargets: (sentryOptions.tracePropagationTargets || []).map((x: string) => new RegExp(x)),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error", "warn"],
      }),
    ],
    tracesSampleRate: sentryOptions.tracesSampleRate || 1.0,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    enabled: enableSentry,
    release: `${sentryOptions.application}@${(window as unknown as { version: string }).version ?? "Unknown"}`
  };

  Sentry.init(configuration);
}
